/* Oral History as Data Additions */
html{
  }
  
  @media print {
      
        html, body {
          
        }
  
        .transcript-content{
            height:auto;
            min-height:5000px;
            overflow:visible;
            display:block;
        }
      
      }
  
      td{vertical-align: top;padding:15px;}
  
    .timestamp{font-size:10px;}
    .grey {
      color: grey;
      height: 3px;
      width: 80%;
      line-height: 2px;
      float:left;
      margin-bottom:1px; 
      overflow: hidden;
  }
  
  .iframe-wrapper{
    position: fixed;
      right: 10px;
      height: 200px;
      width: 370px;
      @include media-query($on-palm) {
        position:relative; 
      }
  }
  
  .anchored-highlight{background:aquamarine;}
    .featured{display:block;float:none; clear:both;}
    .line{ font-size:11px; float:left;width:auto;}
    .words{min-width: 650px;
      @include media-query($on-palm) {min-width: 250px;}}
   .grey .words, .grey .timestamp, .grey .speaker, .grey .line {
      display: inline;
      font-size: 3px;
      padding: 0px 5px;
  }
  
  .filteroptions, .searchinput{font-size:24px; 
      @include media-query($on-palm) { font-size:18px;}}
      
  .notes-container{
  list-style:disc;
      li{margin:10px 0px;}
  }
  
  .toggle_int:hover{color:pink}
  .toggle_int small{font-size:12px;}
  
  
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }
 
/* Footer */

.footer-logo img {   
  max-width: 300px;
 max-height: 60px;}